<template>
  <div class="Awarp">
    <dashCard class="energykanbanBox8" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption"
              @changeScreen="changeScreen">
      <template slot="title">{{commonLangHandler('energykanbanBox11_capacity','装机容量', getZEdata)}}</template>
      <div class="card-content1">
        <chartBoard ref="chart1" :option="chartOption1"/>
      </div>
    </dashCard>
    <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false"
             width="100%" :dialog-style="{ top: '0px' }">
      <div style="height:calc(100vh)">
        <energykanbanBox11 :option="option" :isModal="true" :orginSearchObj="searchObj"/>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: 'energykanbanBox11',
  components: {
    dashCard: () => import('@/component/dashCard.vue'),
    chartBoard: () => import('@/component/chartBoard.vue'),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return {w: 6, h: 6}
      }
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false
        }
      }
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick(_ => {
          this.getOperateWidth()
          var dom = this.$refs['chart1']
          if (!!dom) {
            dom.handleWindowResize()
          }
        })
      },
      deep: true,
      immediate: true
    },
    "option.visible": {//全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj
            this.getData()
          }
        }
      },
      deep: true,
      immediate: true
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData()
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false,//数据加载中

      timetType: [
        {
          code: '按天',
          name: '按天'
        },
        {
          code: '按周',
          name: '按周'
        },
        {
          code: '按月',
          name: '按月'
        },
        {
          code: '按年',
          name: '按年'
        }
      ],
      panelOpen: false,
      searchObj: {
        TYPE: '按月',//按天,按周,按月,按年
        VALUE: this.$moment(),//日期
      },
      chartOption1: {},
      detailInfo: {
        chart1: {}
      },
      colorList: ["#3366FF", "#B4D0FF"],
    }
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo
    }
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE
      var dateStr = this.searchObj.VALUE.format('YYYY-MM-DD')
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf('month');
      var obj = {
        "按天": {
          type: "day1h",
          startDate: VALUE.format('YYYY-MM-DD 00:00:00'),
          endDate: VALUE.format('YYYY-MM-DD 23:59:59'),
        },
        "按周": {
          type: "week",
          startDate: VALUE.startOf('week').format('YYYY-MM-DD 00:00:00'),
          endDate: VALUE.endOf('week').format('YYYY-MM-DD 23:59:59'),
        },
        "按月": {
          type: "month",
          startDate: monthVALUE.startOf('month').format('YYYY-MM-DD 00:00:00'),
          endDate: monthVALUE.endOf('month').format('YYYY-MM-DD 23:59:59'),
        },
        "按年": {
          type: "year",
          startDate: VALUE.startOf('year').format('YYYY-MM-DD 00:00:00'),
          endDate: VALUE.endOf('year').format('YYYY-MM-DD 23:59:59'),
        }
      }
      return obj[TYPE]
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal
      if (!this.option.visible) {
        this.getData()
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return
      }
      var dom = this.$el.querySelector(".card-head-operate")
      if (!!dom) {
        var width = dom.clientWidth
        this.operateWidth = width > 0 ? width + 12 : width
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0
      // 获取整数部分
      const intPart = Math.trunc(value)
      // 整数部分处理，增加,
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      // 预定义小数部分
      let floatPart = ''
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split('.')
      if (valueArray.length === 2) { // 有小数部分
        floatPart = valueArray[1].toString() // 取得小数部分
        return intPartFormat + '.' + floatPart
      }
      return intPartFormat + floatPart
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type
      this.getData()
    },
    openChange(status) {
      this.panelOpen = status
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData()
    },
    onChange(value) {
      console.log("onChange", value, this.searchObj)
      this.getData()
    },
    //每月报警预警数
    getData() {
      let data = {
        "deptCode": this.currentSelectDeptInfo.CODE
      }
      this.isLoading = true
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/equipment/installedcapacity",
        method: "post",
        data
      }).then(res => {
        console.log(res)
        let result = res.data.data
        console.log(result)
        this.detailInfo = result
        let lang = sessionStorage.getItem("locale") || 'CH'
            
            if(lang=='EN'){
              for(let item of this.detailInfo){
                  if(item.machine_type =='储能BMS'){
                      item.machine_type = 'Energy storage BMS'
                  }
                  if(item.machine_type =='储能PCS'){
                      item.machine_type = 'Energy storage PCS'
                  }
                  if(item.machine_type =='充电桩'){
                      item.machine_type = 'Charging station'
                  }
                  if(item.machine_type =='光伏逆变器'){
                      item.machine_type = 'PV Inverter'
                  }
                  if(item.machine_type =='动环监控'){
                      item.machine_type = 'environment supervision'
                  }
                  if(item.machine_type =='发动机控制器'){
                      item.machine_type = 'Engine controller'
                  }
                  if(item.machine_type =='探测器'){
                      item.machine_type = 'detector'
                  }
                  if(item.machine_type =='柴发控制器'){
                      item.machine_type = 'Chaifa controller'
                  }
                  if(item.machine_type =='桩表'){
                      item.machine_type = 'Pile table'
                  }
                  if(item.machine_type =='消防设备'){
                      item.machine_type = 'fire-fighting equipment'
                  }
                  if(item.machine_type =='温湿度传感器'){
                      item.machine_type = 'Temperature and Humidity Sensor'
                  }
                  if(item.machine_type =='电表'){
                      item.machine_type = 'electricity meter'
                  }
                  if(item.machine_type =='空调'){
                      item.machine_type = 'air-conditioning'
                  }
              }
            }
        this.initEchart()
      }).catch((wrong) => {
      }).finally(() => {
        this.isLoading = false
      })
    },
    initEchart() {
      var vm = this
      let seriesData = this.detailInfo
      var series = []
      let categoryData = []
      let values = []
      let counts = []
      seriesData.forEach(item => {
        let a = {
          name: item.machine_type,
          value: item.sum,
          count: item.count
        }
        series.push(a)
        counts.push(item.count)
        values.push(item.sum)
        categoryData.push(item.machine_type)
      })
      var obj = {
        type: 'bar',
        name: '装机容量',
        data: series,
        smooth: false,
        count: counts,
        showSymbol: false,
        // barGap:0,
        barWidth: 10,
      }
      console.log(categoryData)
      console.log(series)
      var option = {
        animation: false,
        layoutAnimation: false,
        grid: {
          left: '0%',
          right: '15%',
          bottom: '0%',
          top: '5%',
          containLabel: true
        },
        // legend: {
        //     right: 0,
        //     top: '10%',
        //     orient: "vertical",
        //     icon: "rect",
        //     itemHeight: 8,
        //     itemWidth: 8,
        //     textStyle: {
        //         fontFamily: 'ABBvoice_WCNSG_Rg',
        //         color: '#1d2129'
        //     }
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          formatter: function (params) {
            let str = "";

            params.forEach((item) => {
              console.log(item)
              str +=
                  "<div>" +
                  `<span style="text-align: left;">${vm.commonLangHandler('energykanbanBox11_capacity','装机容量', vm.getZEdata)}:</span>` +
                  `<span style="text-align: right;"> ${item.data.value}</span><br>` +
                  `<span style="text-align: left;">${vm.LangHandler('numbers','数量')}:</span>` +
                  `<span style="text-align: right;"> ${item.data.count}</span>` +
                  "</div>";
            });
            return str;
          },
        },
        xAxis: {
          type: 'value',
          name: "（"+this.commonLangHandler('energykanbanBox11_capacity','装机容量', this.getZEdata)+"）",
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: 'ABBvoice_WCNSG_Rg',
            color: '#9f9f9f',
            fontSize: 14,
            verticalAlign: "top",
            padding: [8, 0, 0, 10]
          },
          axisLine: {
            lineStyle: {
              color: '#1f1f1f'
            }
          },
          axisLabel: {
            fontFamily: 'ABBvoice_WCNSG_Rg',
            color: '#696969',
            fontSize: 14,
            formatter(v) {
              v = v.toString()
              if (v >= 100000000000) {
                return (v.substring(0, 5) / 10) + '亿'
              } else if (v >= 10000000000) {
                return (v.substring(0, 4) / 10) + '亿'
              } else if (v >= 1000000000) {
                return (v.substring(0, 3) / 10) + '亿'
              } else if (v >= 100000000) {
                return (v.substring(0, 2) / 10) + '亿'
              } else if (v >= 10000000) {
                return v.substring(0, 4) + '万'
              } else if (v >= 1000000) {
                return v.substring(0, 3) + '万'
              } else if (v >= 100000) {
                return v.substring(0, 2) + '万'
              } else if (v >= 10000) {
                return (v.substring(0, 2) / 10) + '万'
              } else if (v >= 1000) {
                return v
              } else if (v <= -100000000000) {
                return "-" + (v.substring(1, 7) / 10) + '亿'
              } else if (v <= -100000000000) {
                return "-" + (v.substring(1, 6) / 10) + '亿'
              } else if (v <= -10000000000) {
                return "-" + (v.substring(1, 5) / 10) + '亿'
              } else if (v <= -1000000000) {
                return "-" + (v.substring(1, 4) / 10) + '亿'
              } else if (v <= -100000000) {
                return "-" + (v.substring(1, 3) / 10) + '亿'
              } else if (v <= -10000000) {
                return "-" + v.substring(1, 5) + '万'
              } else if (v <= -1000000) {
                return "-" + v.substring(1, 4) + '万'
              } else if (v <= -100000) {
                return "-" + v.substring(1, 3) + '万'
              } else if (v <= -10000) {
                return "-" + (v.substring(1, 3) / 10) + '万'
              } else if (v <= -1000) {
                return v
              } else {
                return v
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              width: 0.5,
              color: '#bababa'
            }
          },
          // 控制数据参数最大值和最小值
          // interval: 50,
          // max: 100

        },
        yAxis: {
          type: 'category',
          // name: "(公司)",
          data: categoryData,
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: '#696969'
            }
          },
          axisTick: {
            show: false
          },
          nameTextStyle: {
            fontFamily: 'ABBvoice_WCNSG_Rg',
            color: '#9f9f9f',
            fontSize: 14
          },
          axisLabel: {
            fontFamily: 'ABBvoice_WCNSG_Rg',
            color: '#696969',
            fontSize: 14
          },
        },
        // 控住柱状图样式
        series: obj,
        color: this.colorList
      };

      this.updateChart('chart1', 'chartOption1', option)
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return
      this[optionName] = option
      setTimeout(() => {
        this.$refs[refName].updateChartView()
      }, 500)
    },


  },

  mounted() {
    this.getOperateWidth()
    if (!this.option.visible) {
      this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
.energykanbanBox8 {
  .card-content1 {
    flex: 1;
    height: 100%;

    div {
      width: 100%;
      height: 100%;
    }
  }

}
</style>

